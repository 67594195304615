import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import React from 'react';
import ReactDOM from 'react-dom';

import 'reset-css';
import './index.css';
import App from './App';

import register from './registerServiceWorker';
import { setupDAL } from 'api';
import { isInternetExplorerUserAgent } from './helpers';
import UnsupportedBrowserModal from './components/unsupported-browser-modal';

register();
setupDAL();

ReactDOM.render(
  <React.StrictMode>
    {isInternetExplorerUserAgent() ? <UnsupportedBrowserModal /> : <App />}
  </React.StrictMode>,
  document.getElementById('root'),
);
