import { Component } from 'react';
import './UnsupportedBrowserModal.scss';
import { ReactComponent as InfoIcon } from 'assets/images/info.svg';

class UnsupportedBrowserModal extends Component {
  render() {
    return (
      <div className="unsupported-browser-backdrop">
        <div className="unsupported-browser-wrapper">
          <div className="unsupported-browser">
            <div className="info-icon">
              <InfoIcon width={32} height={32} />
            </div>
            <h5>Unsupported Web Browser</h5>
            <p>
              Internet Explorer is no longer supported. Please try opening this
              link in
              <a
                href="https://www.microsoft.com/en-us/edge"
                target="_blank"
                rel="noreferrer">
                <b> Edge</b>
              </a>
              ,
              <a
                href="https://www.google.com/chrome/"
                target="_blank"
                rel="noreferrer">
                <b> Chrome </b>
              </a>
              or another modern web browser.
            </p>
          </div>
        </div>
      </div>
    );
  }
}

export default UnsupportedBrowserModal;
