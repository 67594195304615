import { isEmpty } from 'lodash';

/**
 * Checks to see if the gallery is password protected, and should show the respective UI.
 * @param {Object} galleryData - object containing data from back-end about gallery.
 */
export const isGalleryPasswordProtected = (galleryData) => {
  if (isEmpty(galleryData)) {
    // We assume that if no data passed it is not protected
    return false;
  }
  return galleryData.password_protected;
};

/**
 * Checks to see if the filled in password, persisted, matches the one in provided gallery info.
 * @param {Object} galleryData - object containing data from back-end about gallery.
 */
export const isPasswordMatching = (galleryData) => {
  if (isEmpty(galleryData)) {
    // We assume that if no data passed it is not protected
    return false;
  }
  const savedPassword = getPersistedPassword(galleryData.id);
  return galleryData.password === savedPassword;
};

/**
 * Returns persisted password by id.
 * @param {String} galleryId - galleryId.
 */
const getPersistedPassword = (galleryId) => {
  return localStorage.getItem(`gallery-${galleryId}-pass`);
};

/**
 * Persists provided password using galleryId as key.
 * @param {String} password - password.
 * @param {String} galleryId - galleryId.
 */
const setPersistedPassword = (password, galleryId) => {
  localStorage.setItem(`gallery-${galleryId}-pass`, JSON.stringify(password));
};

/**
 * Returns photo variant by variantId.
 * @param {Object} media - Media object returned from backend with various variants.
 * @param {String} variantId - Variant type that must be returned.
 * @returns {Object} - PhotoVariant with requested type or undefined if that type wasn't found.
 */
export const getMediaVariant = (media, variantId) => {
  const { current_serving_variants = [] } = media;
  return current_serving_variants.find((item) => {
    return item.name === variantId;
  });
};

/**
 * Returns if app is rendered in iframe
 * @returns {Boolean} - True or False
 */
export const isRenderedInFrame = () => {
  try {
    return window.self !== window.top;
  } catch (e) {
    return true;
  }
};

/**
 * Returns max value of itemProp within items provided
 * @param {Array} items - items to find within
 * @param {String} itemProp - property name to find by
 * @param {String|Number} initialValue - value for initial comparison
 * @returns {Boolean} - True or False
 */
export const findMaxValue = (items = [], itemProp, initialValue = '') => {
  return items.reduce((acc, item) => {
    if (item[itemProp] > acc) {
      acc = item[itemProp];
    }
    return acc;
  }, initialValue);
};

/**
 * Converts date to UTC
 * @param {Date} date - date
 * @returns {Date} - converted date
 */
export function toUTC(date) {
  if (!date) {
    throw "Parameter 'date' is required!";
  }
  const now = new Date(date);
  const offset = now.getTimezoneOffset();
  return new Date(date.setMinutes(date.getMinutes() - offset));
}

/**
 * If the user's current userAgent is IE 11 or earlier, this function will return true. This is used to alert the user
 * of compatibility issues.
 *
 * @returns {boolean}
 */
export const isInternetExplorerUserAgent = () => {
  return (
    /MSIE (\d+\.\d+);/.test(navigator.userAgent) ||
    /Trident\/\d+\.\d+;/.test(navigator.userAgent)
  );
};

/**
 * Check if media tags contain provided tag.
 */
export const isVariantTagMatch = (mediaTags, tag) => {
  return mediaTags.includes(tag);
};
