import axios from 'axios';
import qs from 'qs';

/**
 * Configuration function. Sets base URL for all requests.
 * Also defines query params serialization
 */
export const setupDAL = function () {
  axios.defaults.baseURL = process.env.REACT_APP_API_ENDPOINT;

  axios.defaults.paramsSerializer = (params) => {
    return qs.stringify(params, {
      arrayFormat: 'repeat',
      skipNulls: true,
    });
  };
};

export const request = axios;
