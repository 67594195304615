import { createContext } from 'react';
import noop from 'lodash/noop';

const GalleryContext = createContext({
  gallery: {},
  galleryStatus: null,
  collection: {},
  photos: [],
  loading: false,
  updateContextValues: (valuesToUpdate = {}, callback = noop) => {},
});

export default GalleryContext;
