import { useContext, useState } from 'react';
import GalleryContext from 'contexts/gallery';

const GalleryProvider = (props) => {
  const initialState = useContext(GalleryContext);
  const [state, setState] = useState(initialState);

  return (
    <GalleryContext.Provider
      value={{
        ...state,
        updateContextValues: (valuesToUpdate, callback) => {
          setState({ ...state, ...valuesToUpdate });

          if (typeof callback === 'function') {
            callback();
          }
        },
      }}>
      {props.children}
    </GalleryContext.Provider>
  );
};

export default GalleryProvider;
