import { lazy, Suspense } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import GalleryProvider from 'contexts/gallery/provider';

const GalleryPage = lazy(() => import('pages/GalleryPage'));
const PhotoPage = lazy(() => import('pages/PhotoPage'));

import './App.scss';
import Spinner from './components/spinner';

const App = () => {
  return (
    <div className="App">
      <Router basename="/gallery">
        <Switch>
          <Route exact path="/:galleryId">
            <GalleryProvider>
              <Suspense
                fallback={
                  <div className="loader">
                    <Spinner />
                  </div>
                }>
                <GalleryPage />
              </Suspense>
            </GalleryProvider>
          </Route>
          <Route path="/:galleryId/media/:mediaId">
            <GalleryProvider>
              <Suspense
                fallback={
                  <div className="loader">
                    <Spinner />
                  </div>
                }>
                <PhotoPage />
              </Suspense>
            </GalleryProvider>
          </Route>
        </Switch>
      </Router>
    </div>
  );
};

export default App;
